import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { CustomSelect, Confirmation } from '.'
import { BioTextInput, BioButton, BioTextArea, BioCheckbox } from '../shared'
const { postExpansionMessage } = require('../../utils/postMessage')
import { expansionValidationSchema, FileInput } from './'
import { getLocale, getLocaleUrl } from '../../utils/localeUtils'

export const ExpansionOfferForm = ({ formData }) => {
  const createInitialValues = formData => {
    let initial = {}
    for (const key in formData) {
      initial[key] = formData[key].initialValue || ''
    }
    delete initial.zipcode
    initial.confirmAge = false
    return initial
  }

  const [submitted, setSubmitted] = useState(false)
  const [submitMessage, setSubmitMessage] = useState('')
  const [isSending, setIsSending] = useState(true)
  const [initialValues] = useState(createInitialValues(formData))

  const scrollToErrors = errors => {
    const errorKeys = Object.keys(errors)
    if (errorKeys.length > 0) {
      document.getElementsByName(errorKeys[0])[0].focus()
    }
  }
  const locale = getLocale()
  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={expansionValidationSchema(formData)}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitted(true)
          try {
            let formData = new FormData()

            Object.keys(values).forEach(key => {
              formData.append(key, values[key])
            })

            const res = await postExpansionMessage(formData)
            res.response.status === 200
              ? setSubmitMessage('Deine Nachricht wurde erfolgreich gesendet')
              : setSubmitMessage(
                  'Etwas ist schief gelaufen, bitte versuche es später noch einmal'
                )
          } catch (error) {
            console.log(error)
            setSubmitMessage(
              'Etwas ist schief gelaufen, bitte versuche es später noch einmal'
            )
          }

          setSubmitting(false)
          setIsSending(false)
        }}>
        {({ errors, touched }) => {
          const touchedErrors = Object.entries(errors).find(
            ([k, v]) => touched[k]
          )

          const button = { buttonCta: 'Standorthinweis abschicken' }
          return (
            <Form id="expansion-form">
              <section className="py-12">
                <h2 className="pt-12">Deine Angaben</h2>

                <CustomSelect {...formData.salutation} />
                <BioTextInput {...formData.firstName} />
                <BioTextInput {...formData.lastName} />
                <BioTextInput {...formData.street} />
                <BioTextInput {...formData.city} />
                <BioTextInput {...formData.zipcode} />
                <BioTextInput {...formData.emailAddress} />
                <BioTextInput {...formData.phoneNumber} />
                <BioTextInput {...formData.faxNumber} />
              </section>

              <section className="pb-12">
                <h2>Angaben zum Standort</h2>
                <BioTextInput {...formData.locationStreet} />
                <BioTextInput {...formData.locationCity} />
                <BioTextInput {...formData.locationZipcode} />
              </section>

              <section className="pb-6">
                <h2>Weitere Angaben</h2>
                <BioTextArea {...formData.size} />
                <BioTextArea {...formData.parking} />
                <BioTextArea {...formData.attractions} />
                <BioTextArea {...formData.other} />
              </section>

              {formData.uploadFields && (
                <div className="flex flex-wrap justify-between relative">
                  {formData.uploadFields.map(uploadField => (
                    <FileInput
                      key={uploadField.name}
                      name={uploadField.name}
                      {...uploadField}
                    />
                  ))}
                </div>
              )}

              {/* Honeypot for form-security */}
              <BioCheckbox
                type="checkbox"
                {...formData.confirmAge}
                label={'Ja, ich habe das 16. Lebensjahr vollendet'}
                isForm={true}
                name={'confirmAge'}
              />
              {touchedErrors?.length > 0 && errors && (
                <span className="text-sm text-orange">
                  Es wurden fehlende oder falsche Daten angegeben. Bitte
                  überprüfe Deine Eingaben.
                </span>
              )}
              <div
                className="flex justify-center py-6"
                data-cy="expansion-submit">
                <BioButton
                  type="submit"
                  click={() => scrollToErrors(errors)}
                  button={button}
                />
              </div>

              <div className="flex justify-left py-6">
                <div className="text-md">*Pflichtfeld</div>
              </div>

              <div className="flex justify-left py-6">
                <div className="text-md">
                  Hinweis: Fasse bitte alle relevanten Bilder und Dateien in
                  einem Dokument mit einer maximalen Größe von 10 MB zusammen.
                  Zulässige Formate: jpg, gif, png, pdf, xlsx, docx.
                </div>
              </div>

              <Confirmation
                isSending={isSending}
                isSubmitted={submitted}
                setSubmitted={setSubmitted}
                headline={submitMessage}
                navigate={getLocaleUrl(locale, '/')}
              />
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
